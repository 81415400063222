$(() => {
    'use-strict'
    
    $(document).ready(() => {
        /**-----------------------------
         *  Service Slider
         * ---------------------------*/
        const $serviceCarousel = $('.service-slider');
        if ($serviceCarousel.length > 0) {
            $serviceCarousel.slick({
            dots: false,
            slidesToScroll: 1,
            speed: 400,
            loop: true,
            slidesToShow: 4,
            autoplay: false,
            prevArrow: '<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
            nextArrow: '<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
            responsive: [
                {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
                },
                {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
                },
                {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
                }
            ]
            });
        }
    });

    /**-----------------------------
     *  Partners Slider
     * ---------------------------*/
    const $clientCarousel = $('.client-slider');
    if ($clientCarousel.length > 0) {
        $clientCarousel.owlCarousel({
            loop: true,
            autoplay: false,
            autoPlayTimeout: 1000,
            dots: false,
            nav: false,
            smartSpeed: 1500,
            margin: 30,
            navText:['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                576: {
                    items: 2
                },
                768: {
                    items: 3,
                },
                992: {
                    items: 4,
                }
            }
        });
    }

    /**-----------------------------
     *  Contact Form
     * ---------------------------*/
    //Contact Form Validation
	if($('#contact-form').length){
		$('#contact-form').validate({
			messages: {
				name: {
					required: 'Campo requerido!'
				},
				email: {
					required: 'Campo requerido!',
					email: 'E-mail inválido!'
				},
				phone: {
					required: 'Campo requerido!'
				},
				department: {
					required: 'Campo requerido!'
				}
			},
			rules: {
                department: {
                    required: true,
                    minlength: 1
				},
				name: {
					required: true
				},
				email: {
					required: true,
					email: true
				},
				phone: {
					required: true
				}
			}
		});
    }
    
    $('#contact-form').submit(function(e) {
		if($('#contact-form').valid()) {
			e.preventDefault();
			const form = $(this);
			const data = form.serialize();
            const button = form.find(':submit');
            const buttonText = button.text();
	
			button.prop('disabled', true);
			button.text('Enviando...');
			
			$.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                type: "POST",
                url: form.attr('action'),
                data: data,
                dataType: 'JSON',
                success: function(response){
                    form.trigger('reset');
                    form.find('.message-box').append('<div class="alert alert-success">'+ response.message +'</div>');
                    button.text(buttonText);
                    button.prop('disabled', false);
                    $('select[name="department"]').niceSelect({
                        method: 'update'
                    });
                    grecaptcha.reset();
                    setTimeout(function(){
                        form.find('.alert').remove();
                    }, 2500)
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    const response = JSON.parse(jqXHR.responseText);
                    form.find('.message-box').append('<div class="alert alert-danger">'+response.error+'</div>');
                    button.text(buttonText);
                    button.prop('disabled', false);
                    grecaptcha.reset();
                    setTimeout(function(){
                        form.find('.alert').remove();
                    }, 2500)
                }
			});
		}
	});
});